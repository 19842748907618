import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import {
  ApiError,
  ProdutoDocs,
  ProdutoDocsRequest,
  ProdutoService,
  Seguro,
  SeguroRequest,
} from '../../../slidein_api';
import { fileLink, iconBool, msgAPIError } from '../../helpers';
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_ShowHideColumnsButton,
  MRT_ToggleDensePaddingButton,
  useMantineReactTable,
} from 'mantine-react-table';
import {
  ActionIcon,
  Box,
  Button,
  Checkbox,
  FileInput,
  Group,
  Modal,
  NumberInput,
  Text,
  Textarea,
  TextInput,
  Tooltip,
} from '@mantine/core';
import {
  IconCircleCheck,
  IconCurrencyEuro,
  IconDeviceFloppy,
  IconEdit,
  IconFiles,
  IconLockBolt,
  IconMessageCircle,
  IconNotes,
  IconPackage,
  IconPackages,
  IconPdf,
  IconPlus,
  IconTrash,
  IconTrashX,
  IconUpload,
  IconUser,
  IconUsersGroup,
  IconX,
} from '@tabler/icons-react';
import { MRT_Localization_PT } from 'mantine-react-table/locales/pt';
import { useForm, yupResolver } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { useAuth } from '../../../user-auth/SlideInAuthContext';
import { checkPermission, MASTER } from '../../../components/CheckPermission';

const EMPTYSEGURO: SeguroRequest = {
  descricao: '',
  nome_interno: '',
  titulo_cliente: '',
  valor: undefined,
  campanha: '',
  activo: true,
  inicial: false,
  inscricao_previa: false,
  msg_metodo_inscricao: '',
  codigo_seguradora: '',
};

export function SeguroManager() {
  const { colaborador } = useAuth();
  const params = useParams();
  const [dialogParams, setDialogParams] = useState<{
    create: boolean;
    visible: boolean;
  }>({
    create: true,
    visible: false,
  });
  const [update, setUpdate] = useState<number>(0);
  const [seguros, setSeguros] = useState<Seguro[]>([]);
  const [seguroDocsEdit, setSeguroDocEdit] = useState<{ seguro: Seguro; docs: ProdutoDocs[] }>(
    undefined
  );
  const [docsDiag, setDocsDialog] = useState<boolean>(false);
  const [docsDiagAdd, setDocsDialogAdd] = useState<boolean>(false);

  useEffect(() => {
    ProdutoService.produtoSeguroList({ campanha: params.campanhaID }).then(
      (value) => {
        setSeguros(value);
      },
      (reason) => msgAPIError(reason)
    );
  }, [update]);

  const seguroForm = useForm<Seguro | SeguroRequest>({
    initialValues: EMPTYSEGURO,
    validate: yupResolver(
      Yup.object({
        nome_interno: Yup.string().min(4, 'Tamanho mínimo de 4 caracteres').required('Obrigatório'),
        titulo_cliente: Yup.string().required('Obrigatório'),
        descricao: Yup.string(),
        valor: Yup.number().min(0).required('Obrigatório'),
      })
    ),
  });
  const saveSeguro = async (seguro: Seguro) => {
    if (dialogParams.create) {
      seguro.campanha = params.campanhaID;
      ProdutoService.produtoSeguroCreate({ requestBody: seguro }).then(
        () => {
          setUpdate(update + 1);
          setDialogParams({ create: true, visible: false });
        },
        (error) => {
          seguroForm.setErrors(error.body);
        }
      );
    } else {
      ProdutoService.produtoSeguroUpdate({
        id: seguro.id,
        requestBody: seguro,
      }).then(
        () => {
          setUpdate(update + 1);
          setDialogParams({ create: true, visible: false });
          notifications.show({
            title: `Seguro actualizado!`,
            message: `O Seguro ${seguro.nome_interno} foi actualizado.`,
            withCloseButton: true,
            icon: <IconCircleCheck />,
            color: 'green',
          });
        },
        (error) => {
          seguroForm.setErrors(error.body);
        }
      );
    }
  };

  function criarSeguro() {
    seguroForm.reset();
    setDialogParams({
      create: true,
      visible: true,
    });
  }

  function editSeguro(seguro: Seguro) {
    seguroForm.reset();
    seguroForm.setValues(seguro);
    setDialogParams({
      create: false,
      visible: true,
    });
  }

  function removeSeguro(seguro: Seguro) {
    if (confirm('Confirma que deseja remover o seguro : ' + seguro.nome_interno + ' ?')) {
      ProdutoService.produtoSeguroDestroy({ id: seguro.id }).then(
        () => {
          setUpdate(update + 1);
          notifications.show({
            title: 'Seguro removido!',
            message: `O seguro ${seguro.nome_interno} foi removido.`,
            withCloseButton: true,
            icon: <IconTrashX />,
            color: 'red',
          });
        },
        (reason) => {
          msgAPIError(reason);
        }
      );
    }
  }

  const editSeguroDialog = (
    <Modal
      withCloseButton={false}
      closeOnEscape={false}
      closeOnClickOutside={false}
      size={'auto'}
      opened={dialogParams.visible}
      onClose={() => {
        setDialogParams({ create: false, visible: false });
      }}
      title={dialogParams.create ? 'Criar Seguro' : 'Editar Seguro'}>
      <form onSubmit={seguroForm.onSubmit(saveSeguro)}>
        <TextInput
          label={'Nome Interno'}
          icon={<IconPackage />}
          {...seguroForm.getInputProps('nome_interno')}
        />
        <Text color={'red'}>{seguroForm.errors['non_field_errors']}</Text>
        <TextInput
          label={'Codigo Seguradora'}
          icon={<IconLockBolt />}
          {...seguroForm.getInputProps('codigo_seguradora')}
        />
        <TextInput
          label={'Titulo Cliente'}
          icon={<IconUser />}
          {...seguroForm.getInputProps('titulo_cliente')}
        />

        <NumberInput
          placeholder='Valor'
          label='Valor'
          precision={2}
          icon={<IconCurrencyEuro />}
          hideControls
          decimalSeparator=','
          thousandsSeparator='.'
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          formatter={(value) =>
            !Number.isNaN(parseFloat(value))
              ? // eslint-disable-next-line security/detect-unsafe-regex
                `${value} €`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
              : ''
          }
          {...seguroForm.getInputProps('valor')}
        />
        {!dialogParams.create && (
          <Text color={'red'}>
            Não deve alterar o valor após criado/vendido! Criar um seguro novo e desativar este!
          </Text>
        )}
        <NumberInput
          placeholder='Ilimitado'
          label='Unidades Disponiveis(Stock)'
          precision={0}
          description={'Deixar em branco para ser ilimitado.'}
          min={0}
          icon={<IconPackages />}
          thousandsSeparator='.'
          value={seguroForm.values.unidades !== null ? seguroForm.values.unidades : ''}
          onChange={(value) => {
            if (value !== '') {
              seguroForm.setFieldValue('unidades', value);
            } else {
              seguroForm.setFieldValue('unidades', null);
            }
          }}
        />

        <Checkbox
          mt={'lg'}
          labelPosition='right'
          label=' Activo, disponivel para compra pelos clientes'
          color='green'
          radius='md'
          size='lg'
          {...seguroForm.getInputProps('activo', { type: 'checkbox' })}
        />
        <Checkbox
          mt={'lg'}
          labelPosition='right'
          label='Adicionar automáticamente aos Grupos (na criação)'
          color='green'
          radius='md'
          size='lg'
          {...seguroForm.getInputProps('inicial', { type: 'checkbox' })}
        />
        <Checkbox
          mt={'lg'}
          labelPosition='right'
          label='Inscrição via "Outro  método"'
          color='green'
          radius='md'
          size='lg'
          {...seguroForm.getInputProps('inscricao_previa', { type: 'checkbox' })}
        />
        {seguroForm.values.inscricao_previa && (
          <TextInput
            label={'Método de Inscrição (Mensagem cliente)'}
            icon={<IconMessageCircle />}
            {...seguroForm.getInputProps('msg_metodo_inscricao')}
          />
        )}

        <Textarea
          placeholder='Descrição...'
          label='Descrição'
          size='md'
          icon={<IconNotes />}
          autosize={true}
          minRows={2}
          maxRows={4}
          {...seguroForm.getInputProps('descricao')}
        />
        <NumberInput
          label={'# Ordem de aparencia clientes'}
          min={0}
          placeholder={'Em branco = último.'}
          {...seguroForm.getInputProps('ordem_de_aparencia')}
        />
        <Group
          position='right'
          mt='md'>
          <Button
            leftIcon={<IconDeviceFloppy />}
            variant={'outline'}
            type='submit'>
            Guardar
          </Button>
          <Button
            color={'yellow'}
            variant={'outline'}
            leftIcon={<IconX />}
            type='button'
            onClick={() => setDialogParams({ create: false, visible: false })}>
            Cancelar
          </Button>
        </Group>
      </form>
    </Modal>
  );

  //--------------------------------------------------------------
  const docForm = useForm<ProdutoDocsRequest>({
    initialValues: {
      nome: '',
      produto: undefined,
      file: undefined,
    },

    validate: yupResolver(
      Yup.object({
        nome: Yup.string().min(5, 'Tamanho mínimo de 5 caracteres').required('Obrigatório'),
        file: Yup.mixed().required('Nenhum ficheiro selecionado!'),
      })
    ),
  });
  const saveSeguroDoc = async (doc: ProdutoDocsRequest) => {
    doc.produto = seguroDocsEdit.seguro.id;
    ProdutoService.produtoDocsCreate({
      formData: doc,
    }).then(
      (value) => {
        setUpdate(update + 1);
        setSeguroDocEdit({
          seguro: seguroDocsEdit.seguro,
          docs: [...seguroDocsEdit.docs, value],
        });
        setDocsDialogAdd(false);
      },
      (error) => {
        docForm.setErrors(error.body);
      }
    );
  };

  function adicionarSeguroDoc() {
    docForm.reset();
    docForm.values.produto = seguroDocsEdit.seguro.id;
    setDocsDialogAdd(true);
  }

  const columnsDocs = useMemo<MRT_ColumnDef<ProdutoDocs>[]>(
    () => [
      {
        accessorKey: 'nome',
        header: 'Nome Documento',
      },
      {
        accessorFn: (row) => fileLink(row.nome, row.file),
        header: 'Ficheiro',
        enableEditing: false,
      },
    ],
    []
  );

  const editDocsDialog = () => {
    if (seguroDocsEdit === undefined) {
      return <></>;
    } else {
      return (
        <>
          <Modal
            withCloseButton={true}
            closeOnEscape={false}
            closeOnClickOutside={false}
            size={'xl'}
            title={`Documentos (${seguroDocsEdit.seguro.nome_interno})`}
            opened={docsDiag && !docsDiagAdd}
            onClose={() => setDocsDialog(false)}>
            <MantineReactTable
              displayColumnDefOptions={{
                'mrt-row-actions': {
                  mantineTableHeadCellProps: {
                    align: 'center',
                  },
                  size: 120,
                },
              }}
              renderTopToolbarCustomActions={() => {
                return (
                  <Box sx={{ display: 'flex', gap: '16px', padding: '4px', flexWrap: 'wrap' }}>
                    <Button
                      disabled={!checkPermission(MASTER, colaborador)}
                      leftIcon={<IconPlus />}
                      variant='outline'
                      onClick={() => {
                        adicionarSeguroDoc();
                      }}>
                      Adicionar Documento
                    </Button>
                  </Box>
                );
              }}
              initialState={{
                density: 'xs',
                showGlobalFilter: true,
                pagination: { pageSize: 15, pageIndex: 0 },
              }}
              enableRowActions={checkPermission(MASTER, colaborador)}
              renderRowActions={({ row, table }) => (
                <Box sx={{ display: 'flex', gap: '16px' }}>
                  <Tooltip label='Editar'>
                    <ActionIcon
                      color='blue'
                      onClick={() => table.setEditingRow(row)}>
                      <IconEdit />
                    </ActionIcon>
                  </Tooltip>
                  <Tooltip label='Remover'>
                    <ActionIcon
                      color='red'
                      onClick={() => {
                        if (confirm('Confirma que deseja remover o documento ?')) {
                          ProdutoService.produtoDocsDestroy({ id: row.original.id }).then(
                            () => {
                              setUpdate(update + 1);
                              setSeguroDocEdit({
                                seguro: seguroDocsEdit.seguro,
                                docs: seguroDocsEdit.docs.filter((pa) => {
                                  return pa.id !== row.original.id;
                                }),
                              });

                              notifications.show({
                                title: 'Documento removido!',
                                message: `O documento ${row.original.nome}  foi removido.`,
                                withCloseButton: true,
                                icon: <IconTrashX />,
                                color: 'red',
                              });
                            },
                            (reason) => {
                              msgAPIError(reason);
                            }
                          );
                        }
                      }}>
                      <IconTrashX />
                    </ActionIcon>
                  </Tooltip>
                </Box>
              )}
              columns={columnsDocs}
              data={seguroDocsEdit.docs}
              positionActionsColumn='first'
              enableFullScreenToggle={false}
              localization={MRT_Localization_PT}
              enableEditing={checkPermission(MASTER, colaborador)}
              enablePagination={false}
              enableFilters={false}
              enableHiding={false}
              enableBottomToolbar={false}
              enableGlobalFilter={false}
              enableDensityToggle={false}
              editDisplayMode={'modal'}
              onEditingRowSave={({ exitEditingMode, row, values }) => {
                ProdutoService.produtoDocsPartialUpdate({
                  id: row.original.id,
                  formData: { nome: values.nome },
                }).then(
                  () => {
                    seguroDocsEdit.docs[row.index] = values;
                    setSeguroDocEdit({
                      seguro: seguroDocsEdit.seguro,
                      docs: seguroDocsEdit.docs,
                    });
                  },
                  (reason: ApiError) => {
                    msgAPIError(reason);
                  }
                );
                exitEditingMode();
              }}
            />
          </Modal>
          <Modal
            withCloseButton={false}
            closeOnEscape={false}
            size={'lg'}
            closeOnClickOutside={false}
            opened={docsDiagAdd}
            onClose={() => {
              setDocsDialogAdd(false);
            }}
            title={'Adicionar Documento'}>
            <form onSubmit={docForm.onSubmit(saveSeguroDoc)}>
              <TextInput
                label={'Nome Documento'}
                icon={<IconPdf />}
                {...docForm.getInputProps('nome')}
              />
              <FileInput
                label={'Ficheiro'}
                multiple={false}
                icon={<IconUpload />}
                {...docForm.getInputProps('file')}
              />

              <Group
                position='right'
                mt='md'>
                <Button
                  variant={'outline'}
                  leftIcon={<IconDeviceFloppy />}
                  type='submit'>
                  Guardar
                </Button>
                <Button
                  color={'yellow'}
                  variant={'outline'}
                  leftIcon={<IconX />}
                  type='button'
                  onClick={() => setDocsDialogAdd(false)}>
                  Cancelar
                </Button>
              </Group>
            </form>
          </Modal>
        </>
      );
    }
  };

  //--------------------------------------------------------------

  function dispaAllGruposSeguro(seguro: Seguro) {
    if (
      confirm(
        `Tem a certeza que deseja adicionar o seguro ${seguro.nome_interno} a TODOS os grupos da Campanha?`
      )
    ) {
      ProdutoService.produtoSeguroDisponibilizarATodosOsGruposRetrieve({ id: seguro.id }).then(
        () => {
          notifications.show({
            title: 'Seguro adicionado ao grupos!',
            message: `O seguro ${seguro.nome_interno} foi adicionado a todo os grupos para selecção.`,
            withCloseButton: true,
            icon: <IconUsersGroup />,
          });
        },
        (reason) => {
          msgAPIError(reason);
        }
      );
    }
  }

  const columns = useMemo<MRT_ColumnDef<Seguro>[]>(
    () => [
      { accessorKey: 'ordem_de_aparencia', header: '#', size: 50, Header: '#' },
      { accessorKey: 'nome_interno', header: 'Nome Interno', size: 200 },
      { accessorKey: 'codigo_seguradora', header: 'Codigo Seguradora', size: 200 },
      { accessorKey: 'titulo_cliente', header: 'Título Cliente', size: 200 },
      {
        accessorKey: 'valor',
        header: 'Valor',
        size: 100,
        Cell: (cell) => `${cell.cell.getValue()}€`,
      },
      {
        accessorKey: 'unidades',
        header: 'Uni. Disp.',
        size: 100,
        Cell: (cell) => (cell.cell.getValue()!== null ? `${cell.cell.getValue()}` : 'Ilimitado'),
      },
      {
        accessorFn: (row) => `${row.nr_vendidos} `,
        header: 'Vendidos',
        size: 50,
      },
      {
                   accessorFn: (row) => `${row.nr_vendidos_e_pagos}` || '-',
                   header: 'Reservados(com Pag.)',
                   size: 50,
                 },

      {
        accessorFn: (row) => iconBool(row.activo),
        header: 'Ativo',
        Header: (
          <Tooltip
            withinPortal={true}
            label={'Activo, disponivel para compra'}>
            <div>Act.</div>
          </Tooltip>
        ),
        size: 50,
      },
      {
        accessorFn: (row) => iconBool(row.inicial),
        header: 'Auto',
        size: 50,
        Header: (
          <Tooltip
            withinPortal={true}
            label={'Na criação de um grupo adicionar automaticamente para escolha'}>
            <div>Auto.</div>
          </Tooltip>
        ),
      },
      {
        accessorFn: (row) => iconBool(row.inscricao_previa),
        csv: true,
        header: 'Incrição Previa',
        Header: (
          <Tooltip
            withinPortal={true}
            label={'Incrição Previa,via "Outro  método".'}>
            <div>Inscrição</div>
          </Tooltip>
        ),
        size: 50,
      },

      {
        accessorKey: 'docs',
        csv: false,
        header: 'Documentos',
        Header: 'Documentos',
        Cell: (cell) => (
          <Button
            leftIcon={<IconFiles />}
            onClick={() => {
              setSeguroDocEdit({ seguro: cell.row.original, docs: cell.row.original.docs });
              setDocsDialog(true);
            }}
            variant='outline'>
            {cell.row.original.docs.length} Docs
          </Button>
        ),
      },
    ],
    []
  );

  const table = useMantineReactTable({
    columns,
    data: seguros,
    renderToolbarInternalActions: ({ table }) => (
      <>
        <Button
          disabled={!checkPermission(MASTER, colaborador)}
          leftIcon={<IconPlus />}
          variant='outline'
          onClick={criarSeguro}>
          Adicionar Seguro
        </Button>
        <MRT_ShowHideColumnsButton table={table} />
        <MRT_ToggleDensePaddingButton table={table} />
      </>
    ),
    // renderTopToolbarCustomActions: () => (
    //   <Button
    //     disabled={!seguros.length|| !checkPermission(MASTER, colaborador)}
    //     leftIcon={<IconCsv />}
    //     rightIcon={<IconDownload />}
    //     onClick={exportCSV}
    //     variant='outline'
    //   />
    // ),
    initialState: {
      density: 'xs',
    },
    enableRowActions: checkPermission(MASTER, colaborador),
    renderRowActions: ({ row }) => (
      <Box sx={{ display: 'flex', gap: '4px' }}>
        <Tooltip label='Editar Seguro'>
          <ActionIcon onClick={() => editSeguro(row.original)}>
            <IconEdit />
          </ActionIcon>
        </Tooltip>
        <Tooltip label='Remover Produto'>
          <ActionIcon
            color='red'
            onClick={() => removeSeguro(row.original)}>
            <IconTrash />
          </ActionIcon>
        </Tooltip>
        <Tooltip label='Adicionar como opção a todos Grupos da Campanha'>
          <ActionIcon
            color='yellow'
            onClick={() => dispaAllGruposSeguro(row.original)}>
            <IconUsersGroup />
          </ActionIcon>
        </Tooltip>
      </Box>
    ),

    enablePagination: false,
    enableColumnResizing: false,
    enableColumnFilters: false,
    enableBottomToolbar: false,
    enableGlobalFilter: false,
    enableSorting: true,
    positionActionsColumn: 'first',
    enableFullScreenToggle: false,
    localization: MRT_Localization_PT,
  });

  return (
    <Box>
      <MantineReactTable table={table} />
      {editSeguroDialog}
      {editDocsDialog()}
    </Box>
  );
}
