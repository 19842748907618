import React, { useEffect, useMemo, useState } from 'react';
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_GlobalFilterTextInput,
  MRT_PaginationState,
  MRT_ShowHideColumnsButton,
  MRT_SortingState,
  MRT_ToggleFiltersButton,
  useMantineReactTable,
} from 'mantine-react-table';
import { OpenAPI, Viagem, ViagemService } from '../../../slidein_api';
import { MRT_Localization_PT } from 'mantine-react-table/locales/pt';
import { iconBool, mrtSortingToAPI, msgAPIError } from '../../helpers';
import { Link, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { Button, Flex, LoadingOverlay, Stack, useMantineTheme } from '@mantine/core';
import { IconDownload, IconFileSpreadsheet, IconPlane, IconUsersGroup } from '@tabler/icons-react';
import axios from 'axios';
import FileSaver from 'file-saver';
import { useBread } from '../../../layout/context/BreadContext';

export function CampanhaViagemListView() {
  const { campanhaID } = useParams();
  const { setBread } = useBread();
  useEffect(() => {
    setBread({
      campanha: {
        title: campanhaID,
        href: '/campanha/' + campanhaID,
        icon: <IconPlane />,
      },
      accao: {
        title: 'Viajantes',
        icon: <IconUsersGroup />,
      },
    });
  }, []);
  const theme = useMantineTheme();

  const [viagems, setViagems] = useState<Viagem[]>([]);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 15,
  });

  const tToslideAPI: Record<string, string> = {
    apelido: 'apelido',
    alojamento: 'alojamento',
    actividadesList: 'actividades_list',
    suplementosList: 'suplementos_list',
    descontosList: 'descontos_list',
    seguroContratado: 'seguro',
    cancelada: 'cancelada',
    bloqueadaPagamentos: 'bloqueada_pagamentos',
    chefeDeGrupo: 'chefe_de_grupo',
    comercialResponsavel: 'grupo__comercial_responsavel__nome_proprio',
    adminResponsavel: 'grupo__admin_responsavel__nome_proprio',
    contactoCasa: 'cliente__contacto_casa',
    contactoTelemovel: 'cliente__contacto_telemovel',
    contactoUrgencia: 'cliente__contacto_urgencia',
    creationDate: 'creation_date',
    dataNascimento: 'cliente__data_nascimento',
    email: 'cliente__email',
    emailContactoUrgencia: 'cliente__email_contacto_urgencia',
    emailPais: 'cliente__email_pais',
    nrFactura: 'nr_factura',
    generoSexual: 'cliente__genero_sexual',
    grupo: 'grupo__codigo',
    identificacao: 'cliente__identificacao',
    instituicao: 'grupo__instituicao__nome',
    lastLogin: 'cliente__last_login',
    moradaCodigoPostal: 'cliente__morada_codigo_postal',
    moradaLocalidade: 'cliente__morada_localidade',
    moradaPais: 'cliente__morada_pais',
    moradaPorta: 'cliente__morada_porta',
    moradaRua: 'cliente__morada_rua',
    nacionalidade: 'cliente__nacionalidade',
    idadeNoFim: 'idade_no_fim',
    temDecaMenores: 'tem_deca_menores',
    prestacoesEmAtraso: 'prestacoes_em_atraso',
    nif: 'cliente__nif',
    nomeContactoUrgencia: 'cliente__nome_contacto_urgencia',
    nomeMae: 'cliente__nome_mae',
    nomePai: 'cliente__nome_pai',
    nomeProprio: 'cliente__nome_proprio',
    nomeCompleto: 'cliente__nome_proprio',
    tamanhoTshirt: 'tamanho_tshirt',
    peso: 'peso',
    altura: 'altura',
    calcado: 'calcado',
    validadeIdentificacao: 'cliente__validade_identificacao',
    valorDevido: 'valor_devido',
    valorPago: 'valor_pago',
    valorTotal: 'valor_total',
  };

  function paramsNormalize(columnFilters) {
    const params = {};
    columnFilters.forEach((c) => (params[c.id] = c.value));
    if (tableViagens.getState().columnFilterFns['actividadesList'] == 'notEquals') {
      params['actividadesListNot'] = params['actividadesList'];
      params['actividadesList'] = undefined;
    }
    if (tableViagens.getState().columnFilterFns['suplementosList'] == 'notEquals') {
      params['suplementosListNot'] = params['suplementosList'];
      params['suplementosList'] = undefined;
    }
    if (tableViagens.getState().columnFilterFns['descontosList'] == 'notEquals') {
      params['descontosListNot'] = params['descontosList'];
      params['descontosList'] = undefined;
    }

    if (params['dataNascimento']) {
      const da = params['dataNascimento'][0];
      const db = params['dataNascimento'][1];

      params['dataNascimentoAfter'] = da
        ? `${da.getFullYear()}-${(da.getMonth() + 1).toString().padStart(2, '0')}-${da
            .getDate()
            .toString()
            .padStart(2, '0')}`
        : null;
      params['dataNascimentoBefore'] = db
        ? `${db.getFullYear()}-${(db.getMonth() + 1).toString().padStart(2, '0')}-${db
            .getDate()
            .toString()
            .padStart(2, '0')}`
        : null;
    }
    if (params['creationDate']) {
      const da = params['creationDate'][0];
      const db = params['creationDate'][1];

      params['creationDateAfter'] = da
        ? `${da.getFullYear()}-${(da.getMonth() + 1).toString().padStart(2, '0')}-${da
            .getDate()
            .toString()
            .padStart(2, '0')}`
        : null;
      params['creationDateBefore'] = db
        ? `${db.getFullYear()}-${(db.getMonth() + 1).toString().padStart(2, '0')}-${db
            .getDate()
            .toString()
            .padStart(2, '0')}`
        : null;
    }
    if (params['validadeIdentificacao']) {
      const da = params['validadeIdentificacao'][0];
      const db = params['validadeIdentificacao'][1];

      params['validadeIdentificacaoAfter'] = da
        ? `${da.getFullYear()}-${(da.getMonth() + 1).toString().padStart(2, '0')}-${da
            .getDate()
            .toString()
            .padStart(2, '0')}`
        : null;
      params['validadeIdentificacaoBefore'] = db
        ? `${db.getFullYear()}-${(db.getMonth() + 1).toString().padStart(2, '0')}-${db
            .getDate()
            .toString()
            .padStart(2, '0')}`
        : null;
    }
    if (params['lastLogin']) {
      const da = params['lastLogin'][0];
      const db = params['lastLogin'][1];

      params['lastLoginAfter'] = da
        ? `${da.getFullYear()}-${(da.getMonth() + 1).toString().padStart(2, '0')}-${da
            .getDate()
            .toString()
            .padStart(2, '0')}`
        : null;
      params['lastLoginBefore'] = db
        ? `${db.getFullYear()}-${(db.getMonth() + 1).toString().padStart(2, '0')}-${db
            .getDate()
            .toString()
            .padStart(2, '0')}`
        : null;
    }
    if (params['valorDevido']) {
      params['valorDevidoMin'] = params['valorDevido'][0];
      params['valorDevidoMax'] = params['valorDevido'][1];
    }
    if (params['valorPago']) {
      params['valorPagoMin'] = params['valorPago'][0];
      params['valorPagoMax'] = params['valorPago'][1];
    }
    if (params['valorTotal']) {
      params['valorTotalMin'] = params['valorTotal'][0];
      params['valorTotalMax'] = params['valorTotal'][1];
    }
    if (params['prestacoesEmAtraso']) {
      params['prestacoesEmAtrasoMin'] = params['prestacoesEmAtraso'][0];
      params['prestacoesEmAtrasoMax'] = params['prestacoesEmAtraso'][1];
    }
    if (params['peso']) {
      params['pesoMin'] = params['peso'][0];
      params['pesoMax'] = params['peso'][1];
    }
    if (params['altura']) {
      params['alturaMin'] = params['altura'][0];
      params['alturaMax'] = params['altura'][1];
    }
    if (params['calcado']) {
      params['calcadoMin'] = params['calcado'][0];
      params['calcadoMax'] = params['calcado'][1];
    }
    if (params['idadeNoFim']) {
      params['idadeNoFimMin'] = params['idadeNoFim'][0];
      params['idadeNoFimMax'] = params['idadeNoFim'][1];
    }

    return params;
  }

  const fetchData = async () => {
    setIsRefetching(true);
    await ViagemService.viagemList({
      ...paramsNormalize(columnFilters),
      campanha: campanhaID,
      page: pagination.pageIndex + 1,
      pageSize: pagination.pageSize,
      search: globalFilter ?? '',
      ordering: mrtSortingToAPI(sorting, tToslideAPI),
    }).then(
      (value) => {
        setViagems(value.results);
        setRowCount(value.count);
        setIsRefetching(false);
      },
      (reason) => {
        msgAPIError(reason);
        setIsRefetching(false);
      }
    );
  };
  useEffect(() => {
    if (!isRefetching) {
      setIsRefetching(true);
      fetchData();
    }
  }, [pagination.pageIndex]);

  useEffect(() => {
    if (!isRefetching && rowCount >= 0) {
      setIsRefetching(true);
      setPagination({ pageIndex: 0, pageSize: 15 });
      fetchData();
    }
  }, [columnFilters, globalFilter, sorting, pagination.pageSize]);

  const columns = useMemo<MRT_ColumnDef<Viagem>[]>(
    () => [
      {
        accessorKey: 'chefe_de_grupo',
        id: 'chefeDeGrupo',
        enableColumnFilterModes: false,
        header: 'Chefe de Grupo',
        filterVariant: 'checkbox',
        Cell: ({ cell }) => iconBool(cell.row.original.chefe_de_grupo),
      },
      {
        enableColumnFilterModes: false,
        accessorKey: 'cancelada',
        id: 'cancelada',
        header: 'Cancelada',
        filterVariant: 'checkbox',
        Cell: ({ cell }) => iconBool(cell.row.original.cancelada),
      },
      {
        enableColumnFilterModes: false,
        accessorKey: 'bloqueada_pagamentos',
        id: 'bloqueadaPagamentos',
        header: 'Bloqueada Falta Pagamentos',
        filterVariant: 'checkbox',
        Cell: ({ cell }) => iconBool(cell.row.original.bloqueada_pagamentos),
      },
      {
        enableColumnFilterModes: false,
        accessorKey: 'nr_factura',
        id: 'nrFactura',
        header: 'Nrº Factura',
        filterVariant: 'text',
      },
      {
        enableColumnFilterModes: false,
        accessorFn: (vi) => vi.idade_no_fim,
        header: 'Idade(Fim da Viagem)',
        id: 'idadeNoFim',
        filterVariant: 'range',
      },
      {
        enableColumnFilterModes: false,
        accessorKey: 'tem_deca_menores',
        id: 'temDecaMenores',
        header: 'Tem Dec.Menores',
        filterVariant: 'checkbox',
        Cell: ({ cell }) => iconBool(cell.row.original.tem_deca_menores),
      },
      {
        enableColumnFilterModes: false,
        accessorFn: (row) => row.alojamento,
        id: 'alojamento',
        header: 'Alojamento',
        enableSorting: false,
        filterVariant: 'text',
      },
      {
        enableColumnFilterModes: false,
        accessorFn: (row) => row.seguro_contratado,
        id: 'seguroContratado',
        header: 'Seguro',
        enableSorting: false,
        filterVariant: 'text',
      },
      {
        enableColumnFilterModes: false,
        accessorFn: (row) => row.seguro_apolice,
        id: 'apoliceSeguro',
        header: 'Apolice Seguro',
        enableSorting: false,
        filterVariant: 'text',
      },
      {
        accessorFn: (row) => row.actividades_list,
        id: 'actividadesList',
        header: 'Actividades',
        enableSorting: false,
        enableColumnFilterModes: true,
        columnFilterModeOptions: ['equals', 'notEquals'],
        filterVariant: 'text',
        filterFn: 'equals',
      },
      {
        accessorFn: (row) => row.suplementos_list,
        id: 'suplementosList',
        header: 'Suplementos',
        enableSorting: false,
        enableColumnFilterModes: true,
        columnFilterModeOptions: ['equals', 'notEquals'],
        filterVariant: 'text',
        filterFn: 'equals',
      },
      {
        accessorFn: (row) => row.descontos_list,
        id: 'descontosList',
        header: 'Descontos',
        enableSorting: false,
        enableColumnFilterModes: true,
        columnFilterModeOptions: ['equals', 'notEquals'],
        filterVariant: 'text',
        filterFn: 'equals',
      },
      {
        enableColumnFilterModes: false,
        accessorFn: (originalRow) => originalRow.cliente.nome_simples,
        id: 'nomeCompleto',
        header: 'Nome',
        filterVariant: 'text',
        Cell: (props: { cell }) => (
          <Link to={'/campanha/' + campanhaID + '/viagem/' + props.cell.row.original.id}>
            {props.cell.row.original.cliente.nome_simples}
          </Link>
        ),
      },
      {
        enableColumnFilterModes: false,
        accessorFn: (vi) => vi.cliente.email,
        id: 'email',
        header: 'Email',
        filterVariant: 'text',
        Cell: (props: { cell }) => (
          <a href={'mailto:' + props.cell.row.original.cliente.email}>
            {props.cell.row.original.cliente.email}
          </a>
        ),
      },
      {
        enableColumnFilterModes: false,
        accessorFn: (row) => row.cliente.contacto_telemovel,
        header: 'Telemovel',
        id: 'contactoTelemovel',
        filterVariant: 'text',
      },
      {
        enableColumnFilterModes: false,
        accessorFn: (row) => row.cliente.genero_sexual,
        header: 'Género',
        id: 'generoSexual',
        filterVariant: 'text',
      },
      {
        enableColumnFilterModes: false,
        accessorFn: (row) => row.cliente.nif,
        header: 'NIF',
        id: 'nif',
        filterVariant: 'text',
      },
      {
        enableColumnFilterModes: false,
        accessorFn: (row) => row.cliente.identificacao,
        header: 'ID',
        id: 'identificacao',
        filterVariant: 'text',
      },
      {
        enableColumnFilterModes: false,
        accessorFn: (row) => row.cliente.validade_identificacao,
        header: 'Validade ID',
        id: 'validadeIdentificacao',
        filterVariant: 'date-range',
      },
      {
        enableColumnFilterModes: false,
        accessorFn: (row) => row.cliente.nacionalidade,
        header: 'Nacionalidade',
        id: 'nacionalidade',
        filterVariant: 'text',
      },
      {
        enableColumnFilterModes: false,
        accessorFn: (row) => row.cliente.contacto_casa,
        header: 'Telefone casa',
        id: 'contactoCasa',
        filterVariant: 'text',
      },
      {
        enableColumnFilterModes: false,
        accessorFn: (row) => row.cliente.morada_rua,
        header: 'Rua',
        id: 'moradaRua',
        filterVariant: 'text',
      },
      {
        enableColumnFilterModes: false,
        accessorFn: (row) => row.cliente.morada_porta,
        header: 'Porta',
        id: 'moradaPorta',
        filterVariant: 'text',
      },
      {
        enableColumnFilterModes: false,
        accessorFn: (row) => row.cliente.morada_localidade,
        header: 'Localidade',
        id: 'moradaLocalidade',
        filterVariant: 'text',
      },
      {
        enableColumnFilterModes: false,
        accessorFn: (row) => row.cliente.morada_codigo_postal,
        header: 'Codigo Postal',
        id: 'moradaCodigoPostal',
        filterVariant: 'text',
      },
      {
        enableColumnFilterModes: false,
        accessorFn: (row) => row.cliente.morada_pais,
        header: 'Pais',
        id: 'moradaPais',
        filterVariant: 'text',
      },
      {
        enableColumnFilterModes: false,
        accessorFn: (row) => row.cliente.nome_pai,
        header: 'Nome Pai',
        id: 'nomePai',
        filterVariant: 'text',
      },
      {
        enableColumnFilterModes: false,
        accessorFn: (row) => row.cliente.nome_mae,
        header: 'Nome Mãe',
        id: 'nomeMae',
        filterVariant: 'text',
      },
      {
        enableColumnFilterModes: false,
        accessorFn: (row) => row.cliente.email_pais,
        header: 'Email Pai/Mãe',
        id: 'emailPais',
        filterVariant: 'text',
        Cell: (props: { cell }) => (
          <a href={'mailto:' + props.cell.row.original.cliente.email_pais}>
            {props.cell.row.original.cliente.email_pais}
          </a>
        ),
      },
      {
        enableColumnFilterModes: false,
        accessorFn: (vi) => dayjs(vi.cliente.data_nascimento).format('YYYY-MM-DD'),
        id: 'dataNascimento',
        header: 'Data Nascimento',
        filterVariant: 'date-range',
      },
      {
        enableColumnFilterModes: false,
        accessorFn: (row) => row.cliente.nome_contacto_urgencia,
        header: 'Nome urgência',
        id: 'nomeContactoUrgencia',
        filterVariant: 'text',
      },
      {
        enableColumnFilterModes: false,
        accessorFn: (row) => row.cliente.contacto_urgencia,
        header: 'Telefone Urgência',
        id: 'contactoUrgencia',
        filterVariant: 'text',
      },
      {
        enableColumnFilterModes: false,
        accessorFn: (row) => row.cliente.email_contacto_urgencia,
        header: 'Email Urgência',
        id: 'emailContactoUrgencia',
        filterVariant: 'text',
        Cell: (props: { cell }) => (
          <a href={'mailto:' + props.cell.row.original.cliente.email_contacto_urgencia}>
            {props.cell.row.original.cliente.email_contacto_urgencia}
          </a>
        ),
      },
      {
        enableColumnFilterModes: false,
        accessorKey: 'prestacoes_em_atraso',
        id: 'prestacoesEmAtraso',
        header: 'Prestações em Atraso',
        filterVariant: 'range',
        filterFn: 'betweenInclusive',
      },
      {
        enableColumnFilterModes: false,
        accessorKey: 'valor_total',
        id: 'valorTotal',
        header: 'Total',
        filterVariant: 'range',
        filterFn: 'betweenInclusive',
        Cell: ({ cell }) => {
          return cell.row.original.valor_total + '€';
        },
      },
      {
        enableColumnFilterModes: false,
        accessorKey: 'valor_pago',
        id: 'valorPago',
        header: 'Pago',
        filterVariant: 'range',
        filterFn: 'betweenInclusive',
        Cell: ({ cell }) => {
          return cell.row.original.valor_pago + '€';
        },
      },
      {
        enableColumnFilterModes: false,
        accessorKey: 'valor_devido',
        id: 'valorDevido',
        header: 'Devido',
        filterVariant: 'range',
        filterFn: 'betweenInclusive',
        Cell: ({ cell }) => {
          return cell.row.original.valor_devido + '€';
        },
      },
      {
        enableColumnFilterModes: false,
        accessorFn: (vi) => vi.quarto || '-',
        id: 'quarto',
        header: 'Quarto',
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        enableColumnFilterModes: false,
        accessorFn: (vi) => vi.transporte || '-',
        id: 'transporte',
        header: 'Transporte',
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        enableColumnFilterModes: false,
        accessorFn: (vi) => vi.grupo.codigo || '-',
        id: 'grupo',
        header: 'Grupo',
        filterVariant: 'text',
        Cell: ({ row }: { row }) => (
          <Link to={'/campanha/' + campanhaID + '/grupo/' + row.original.grupo.codigo}>
            {row.original.grupo.codigo}
          </Link>
        ),
      },
      {
        enableColumnFilterModes: false,
        accessorFn: (vi) => vi.grupo.admin_responsavel,
        id: 'adminResponsavel',
        header: 'Administrador',
        filterVariant: 'text',
      },
      {
        enableColumnFilterModes: false,
        accessorFn: (vi) => vi.grupo.comercial_responsavel,
        id: 'comercialResponsavel',
        header: 'Comercial',
        filterVariant: 'text',
      },
      {
        enableColumnFilterModes: false,
        accessorFn: (vi) => vi.grupo.instituicao,
        id: 'instituicao',
        header: 'Instituição',
        filterVariant: 'text',
      },
      {
        enableColumnFilterModes: false,
        accessorKey: 'tamanho_tshirt',
        id: 'tamanhoTshirt',
        header: 'T-shirt',
        filterVariant: 'text',
      },
      {
        enableColumnFilterModes: false,
        accessorKey: 'peso',
        id: 'peso',
        header: 'Peso',
        filterVariant: 'range',
        filterFn: 'betweenInclusive',
      },
      {
        enableColumnFilterModes: false,
        accessorKey: 'altura',
        id: 'altura',
        header: 'Altura',
        filterVariant: 'range',
        filterFn: 'betweenInclusive',
      },
      {
        enableColumnFilterModes: false,
        accessorKey: 'calcado',
        id: 'calcado',
        header: 'Calçado',
        filterVariant: 'range',
        filterFn: 'betweenInclusive',
      },
      {
        enableColumnFilterModes: false,
        accessorFn: (vi) => dayjs(vi.cliente.creation_date).format('YYYY-MM-DD HH:mm'),
        header: 'Data Inscrição Viagem ',
        id: 'creationDate',
        filterVariant: 'date-range',
      },
      {
        enableColumnFilterModes: false,
        accessorFn: (vi) => dayjs(vi.cliente.last_login).format('YYYY-MM-DD HH:mm'),
        header: 'Último login',
        id: 'lastLogin',
        filterVariant: 'date-range',
      },
      {
        enableColumnFilterModes: false,
        accessorFn: (vi) => vi.cliente.notas_cliente,
        header: 'Notas Importantes',
        id: 'notasCliente',
        enableColumnFilter: false,
        enableSorting: false,
        enableGrouping: false,
      },
    ],
    []
  );

  const tableViagens = useMantineReactTable({
    data: viagems || [],
    columns,
    enableGrouping: true,
    enableClickToCopy: true,
    enableRowActions: false,
    columnResizeMode: 'onEnd',
    positionActionsColumn: 'first',
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    enableMultiSort: true,
    enableColumnOrdering: true,
    enableRowSelection: true,
    enableMultiRowSelection: true,
    enableSelectAll: true,
    selectAllMode: 'all',
    positionGlobalFilter: 'none',
    enableColumnFilterModes: true,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    getRowId: (row) => row.id.toString(),
    rowCount: rowCount,
    state: {
      columnFilters: columnFilters,
      globalFilter: globalFilter,
      // isLoading: isLoading, this is crashing setState xenanings....
      pagination: pagination,
      showProgressBars: isRefetching,
      sorting: sorting,
    },
    initialState: {
      density: 'xs',
      showGlobalFilter: true,
      pagination: { pageSize: 15, pageIndex: 0 },
      columnOrder: [
        'cancelada',
        'bloqueadaPagamentos',
        'chefeDeGrupo',
        'nomeSimples',
        'nomeProprio',
        'nomeCompleto',
        'email',
        'nrFactura',
        'adminResponsavel',
        'comercialResponsavel',
        'alojamento',
        'actividadesList',
        'suplementosList',
        'descontosList',
        'seguroContratado',
        'apoliceSeguro',
        'contactoCasa',
        'contactoTelemovel',
        'contactoUrgencia',
        'creationDate',
        'dataNascimento',
        'emailContactoUrgencia',
        'emailPais',
        'generoSexual',
        'grupo',
        'identificacao',
        'instituicao',
        'valorPago',
        'prestacoesEmAtraso',
        'lastLogin',
        'moradaCodigoPostal',
        'moradaLocalidade',
        'moradaPais',
        'moradaPorta',
        'moradaRua',
        'nacionalidade',
        'idadeNoFim',
        'temDecaMenores',
        'nif',
        'nomeContactoUrgencia',
        'nomeMae',
        'nomePai',
        'quarto',
        'tamanhoTshirt',
        'peso',
        'altura',
        'calcada',
        'transporte',
        'validadeIdentificacao',
        'valorDevido',
        'valorTotal',
        'notasCliente',
      ],
      columnVisibility: {
        cancelada: true,
        bloqueadaPagamentos: false,
        chefeDeGrupo: true,
        comercialResponsavel: false,
        adminResponsavel: true,
        contactoCasa: false,
        contactoTelemovel: false,
        contactoUrgencia: false,
        creationDate: false,
        dataNascimento: false,
        email: true,
        emailContactoUrgencia: false,
        emailPais: false,
        nrFactura: false,
        generoSexual: false,
        alojamento: true,
        actividadesList: true,
        suplementosList: true,
        descontosList: false,
        seguroContratado: true,
        apoliceSeguro: false,
        grupo: true,
        identificacao: false,
        instituicao: true,
        prestacoesEmAtraso: true,
        lastLogin: false,
        moradaCodigoPostal: false,
        moradaLocalidade: false,
        moradaPais: false,
        moradaPorta: false,
        moradaRua: false,
        nacionalidade: false,
        idadeNoFim: false,
        temDecaMenores: false,
        nif: false,
        nomeContactoUrgencia: false,
        nomeSimples: true,
        nomeMae: false,
        nomePai: false,
        nomeProprio: true,
        nomeCompleto: true,
        quarto: false,
        tamanhoTshirt: false,
        peso: false,
        altura: false,
        calcado: false,
        transporte: false,
        validadeIdentificacao: false,
        valorDevido: false,
        valorPago: true,
        valorTotal: false,
        notasCliente: false,
      },
    },
    mantineTableProps: {
      withColumnBorders: true,
    },
    enableFullScreenToggle: false,
    localization: MRT_Localization_PT,
    globalFilterFn: 'contains',
    positionToolbarAlertBanner: 'bottom',
    mantineTableBodyCellProps: ({ row }) => ({
      sx: {
        textDecoration: row.original.cancelada ? 'line-through' : '',
        fontStyle: row.original.cancelada ? 'italic' : '',
        backgroundColor:
          theme.colorScheme == 'dark'
            ? row.original.prestacoes_em_atraso === 0
              ? 'rgba(0,100,0,0.1)'
              : 'rgba(139,0,0,0.2)'
            : row.original.prestacoes_em_atraso === 0
            ? 'lightgreen'
            : 'palevioletred',
      },
    }),
    renderToolbarInternalActions: ({ table }) => (
      <Flex
        direction={'row'}
        wrap={'wrap'}
        gap={'sm'}
        justify={'space-evenly'}>
        <MRT_GlobalFilterTextInput table={tableViagens} />

        <Button
          color='lightblue'
          onClick={() => {
            setIsRefetching(true);
            const cenas: {
              actividadesList?: string;
              actividadesListNot?: string;
              alojamento?: string;
              apoliceSeguro?: string;
              seguroContratado?: string;
              adminResponsavel?: string;
              apelido?: string;
              campanha?: string;
              cancelada?: boolean;
              bloqueadaPagamentos?: boolean;
              chefeDeGrupo?: boolean;
              comercialResponsavel?: string;
              contactoCasa?: string;
              contactoTelemovel?: string;
              contactoUrgencia?: string;
              creationDateAfter?: string;
              creationDateBefore?: string;
              dataNascimentoAfter?: string;
              dataNascimentoBefore?: string;
              descontosList?: string;
              descontosListNot?: string;
              email?: string;
              emailContactoUrgencia?: string;
              emailPais?: string;
              generoSexual?: string;
              suplementosList?: string;
              suplementosListNot?: string;
              grupo?: string;
              identificacao?: string;
              instituicao?: string;
              lastLoginAfter?: string;
              lastLoginBefore?: string;
              moradaCodigoPostal?: string;
              moradaLocalidade?: string;
              moradaPais?: string;
              moradaPorta?: string;
              moradaRua?: string;
              nacionalidade?: string;
              idadeNoFimMax?: number;
              idadeNoFimMin?: number;
              temDecaMenores?: boolean;
              nif?: string;
              nomeCompleto?: string;
              nomeContactoUrgencia?: string;
              nomeMae?: string;
              nomePai?: string;
              nomeProprio?: string;
              nrFactura?: string;
              ordering?: string;
              page?: number;
              pageSize?: number;
              prestacoesEmAtrasoMax?: number;
              prestacoesEmAtrasoMin?: number;
              search?: string;
              tamanhoTshirt?: string;
              pesoMin?: number;
              pesoMax?: number;
              alturaMin?: number;
              alturaMax?: number;
              calcadoMin?: number;
              calcadoMax?: number;
              validadeIdentificacaoAfter?: string;
              validadeIdentificacaoBefore?: string;
              valorDevidoMax?: number;
              valorDevidoMin?: number;
              valorPagoMax?: number;
              valorPagoMin?: number;
              valorTotalMax?: number;
              valorTotalMin?: number;
            } = {
              ...paramsNormalize(columnFilters),
              search: globalFilter ?? '',
              ordering: mrtSortingToAPI(sorting, tToslideAPI),
            };
            const url = `${OpenAPI.BASE}/gestao/viagem/mapa`;
            axios
              .get(url, {
                responseType: 'blob',
                params: {
                  campanha:campanhaID,
                  actividades_list: cenas.actividadesList,
                  actividades_list_not: cenas.actividadesListNot,
                  seguro_apolice: cenas.apoliceSeguro,
                  descontos_list: cenas.descontosList,
                  descontos_list_not: cenas.descontosListNot,
                  suplementos_list: cenas.suplementosList,
                  suplementos_list_not: cenas.suplementosListNot,
                  alojamento: cenas.alojamento,
                  seguro_contratado: cenas.seguroContratado,
                  admin_responsavel: cenas.adminResponsavel,
                  apelido: cenas.apelido,
                  cancelada: cenas.cancelada,
                  bloqueada_pagamentos: cenas.bloqueadaPagamentos,
                  chefe_de_grupo: cenas.chefeDeGrupo,
                  comercial_responsavel: cenas.comercialResponsavel,
                  contacto_casa: cenas.contactoCasa,
                  contacto_telemovel: cenas.contactoTelemovel,
                  contacto_urgencia: cenas.contactoUrgencia,
                  creation_date_after: cenas.creationDateAfter,
                  creation_date_before: cenas.creationDateBefore,
                  data_nascimento_after: cenas.dataNascimentoAfter,
                  data_nascimento_before: cenas.dataNascimentoBefore,
                  email: cenas.email,
                  email_contacto_urgencia: cenas.emailContactoUrgencia,
                  email_pais: cenas.emailPais,
                  genero_sexual: cenas.generoSexual,
                  grupo: cenas.grupo,
                  identificacao: cenas.identificacao,
                  instituicao: cenas.instituicao,
                  last_login_after: cenas.lastLoginAfter,
                  last_login_before: cenas.lastLoginBefore,
                  morada_codigo_postal: cenas.moradaCodigoPostal,
                  morada_localidade: cenas.moradaLocalidade,
                  morada_pais: cenas.moradaPais,
                  morada_porta: cenas.moradaPorta,
                  morada_rua: cenas.moradaRua,
                  nacionalidade: cenas.nacionalidade,
                  idade_no_fim_max: cenas.idadeNoFimMax,
                  idade_no_fim_min: cenas.idadeNoFimMin,
                  tem_deca_menores: cenas.temDecaMenores,
                  nif: cenas.nif,
                  nome_completo: cenas.nomeCompleto,
                  nome_contacto_urgencia: cenas.nomeContactoUrgencia,
                  nome_mae: cenas.nomeMae,
                  nome_pai: cenas.nomePai,
                  nome_proprio: cenas.nomeProprio,
                  nr_factura: cenas.nrFactura,
                  ordering: cenas.ordering,
                  page: cenas.page,
                  page_size: cenas.pageSize,
                  prestacoes_em_atraso_max: cenas.prestacoesEmAtrasoMax,
                  prestacoes_em_atraso_min: cenas.prestacoesEmAtrasoMin,
                  search: cenas.search,
                  tamanho_tshirt: cenas.tamanhoTshirt,
                  peso_min: cenas.pesoMin,
                  peso_max: cenas.pesoMax,
                  altura_min: cenas.alturaMin,
                  altura_max: cenas.alturaMax,
                  calcado_min: cenas.calcadoMin,
                  calcado_max: cenas.calcadoMax,
                  validade_identificacao_after: cenas.validadeIdentificacaoAfter,
                  validade_identificacao_before: cenas.validadeIdentificacaoBefore,
                  valor_devido_max: cenas.valorDevidoMax,
                  valor_devido_min: cenas.valorDevidoMin,
                  valor_pago_max: cenas.valorPagoMax,
                  valor_pago_min: cenas.valorPagoMin,
                  valor_total_max: cenas.valorTotalMax,
                  valor_total_min: cenas.valorTotalMin,
                },
              })
              .then(
                (res) => {
                  const blob = new Blob([res.data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
                  });
                  FileSaver.saveAs(blob, 'viajantes.xlsx');
                  setIsRefetching(false);
                  return res;
                },
                (reason) => {
                  msgAPIError(reason);
                  setIsRefetching(false);
                }
              );
          }}
          rightIcon={<IconFileSpreadsheet />}
          leftIcon={<IconDownload />}
          variant='outline'>
          .xlsx
        </Button>
        <MRT_ShowHideColumnsButton table={tableViagens} />
        <MRT_ToggleFiltersButton table={tableViagens} />
      </Flex>
    ),
  });

  return (
    <Stack>
      <LoadingOverlay visible={isRefetching}></LoadingOverlay>
      <MantineReactTable table={tableViagens} />
    </Stack>
  );
}
